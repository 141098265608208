import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

import React, { Suspense, lazy }  from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { store, persistor  } from './logic/stores/store';
import { PersistGate } from 'redux-persist/integration/react';

import './style/main.scss';
import { isHomolog } from './logic/helpers';

const Layout = lazy(() => import('./layout/layout'));
const Home = lazy(() => import('./views/home'));
const DadosInstalacao = lazy(() => import('./views/dadosInstalacao'));
const DadosResponsavel = lazy(() => import('./views/dadosResponsavel'));
const DadosEmpresa = lazy(() => import('./views/dadosEmpresa'));
const Finalizacao = lazy(() => import('./views/finalizacao'));
const PoliticaPrivacidade = lazy(() => import('./views/politicaPrivacidade'));
const PoliticaCookies = lazy(() => import('./views/politicaCookies'));
const ConfirmarDados = lazy(() => import('./views/confirmarDados'));
const EscolherDados = lazy(() => import('./views/escolherDados'));

const config = {
  kwhMinPF: 110, // Pag 1 (Nala) => PF
  kwhMinPJ: 110, // Pag 1 (Nala) => PJ
    
  kwhMinPFMonofasico: 110, // Pag 2 (Nala) => PF => Monof.
  kwhMinPFBifasico: 110, // Pag 2 (Nala) => PF => Bif.
  kwhMinPFTrifasico: 110, // Pag 2 (Nala) => PF => Trif.

  kwhMinPJMonofasico: 110, // Pag 2 (Nala) => PJ => Monof.
  kwhMinPJBifasico: 110, // Pag 2 (Nala) => PJ => Bif.
  kwhMinPJTrifasico: 110, // Pag 2 (Nala) => PJ => Trif.

  kwhMultiplicador: 1,
  kwhSubtracaoMonofasico: 30,
  kwhSubtracaoBifasico: 30,
  kwhSubtracaoTrifasico: 30,
};

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <Suspense fallback={<div></div>}>
            <Switch>
              <Route path={"/"} exact render={props => <Layout Component={Home} menuOpen={false} config={config} {...props} />} />
              <Route path={"/dadosInstalacao"} render={props => <Layout Component={DadosInstalacao} menuOpen={true}config={config} {...props} />} />
              <Route path={"/dadosCliente"} render={props => <Layout Component={DadosResponsavel} menuOpen={true} config={config} {...props} />} />
              <Route path={"/escolherDados"} render={props => <Layout Component={EscolherDados} menuOpen={true} config={config} {...props} />} />
              <Route path={"/dadosResponsavel"} render={props => <Layout Component={DadosResponsavel} menuOpen={true} config={config} {...props} />} />
              <Route path={"/dadosEmpresa"} render={props => <Layout Component={DadosEmpresa} menuOpen={true} config={config} {...props} />} />
              <Route path={"/confirmarDados"} render={props => <Layout Component={ConfirmarDados} menuOpen={true} config={config} {...props} />} />
              <Route path={"/finalizacao"} render={props => <Layout Component={Finalizacao} menuOpen={true} config={config} {...props} />} />
              <Route path={"/politicaprivacidade"} render={props => <Layout Component={PoliticaPrivacidade} menuOpen={true} config={config} {...props} />} />
              <Route path={"/politicacookies"} render={props => <Layout Component={PoliticaCookies} menuOpen={true} config={config} {...props} />} />
              <Route exact path="/areadocliente" render={() => (window.location = "https://area-cliente.solatioenergialivre.com.br/#/dashboard")} />
              <Route path={"/*"} exact render={props => <Layout Component={Home} menuOpen={false} config={config} {...props} />} />
            </Switch>
          </Suspense>
        </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root-react")
);
