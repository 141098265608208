
export const GetWP = async (tipo) => {

    var data = await fetch('https://solatioenergialivre.com.br/wp-json/solatio/apis/v1/' + tipo, {
        method: 'GET',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(response => response.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        });

    return data;
};

export const PostWP = async (tipo, dados) => {

    var data = await fetch('https://solatioenergialivre.com.br/wp-json/solatio/apis/v1/' + tipo, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dados)
    }).then(response => response.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        });

    return data;
};

export const PostWPHomolog = async (tipo, dados) => {

    var data = await fetch('https://solatioenergialivre.com.br/wp-json/solatio/apis/v1/' + tipo, {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(dados)
    }).then(response => response.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        });

    return data;
};

export const POSTContratoFiles = async (dados) => {

    var data = await fetch('https://solatioenergialivre.com.br/wp-json/solatio/apis/v1/saveFiles', {
        method: 'POST',
        body: dados
    }).then(response => response.json())
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        });

    return data;
};


export const GetGenerico = async (endpoint, header) => {

    var data = await fetch(endpoint, {
        method: 'GET',
        headers: {           
            ...header
        }
    }).then(response => response.json())
        .then(response => {
            response['status'] = 'ok';
            return response;
        })
        .catch(error => {
            return error;
        });

    return data;
};
export const GetXMLGenerico = async (endpoint, header) => {


    return fetch(endpoint, {
        method: 'GET',
        mode: 'no-cors',
        headers: {

            
            ...header
        }
    })
    .then(response => response.text())
    // .then(str => (new window.DOMParser()).parseFromString(str, "text/xml"))
    .then(data => console.log(data))


};