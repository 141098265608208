import { GetGenerico, GetWP } from './request';
import { cepsInvalidos } from './ceps';
import { element, string } from 'prop-types';

export const onlyNumbers = (value) => {
    return value.replace(/\D/g, '');
}

export const uniqueArray = (array) => {
    var result = Array.from(new Set(array));
    return result
}

export const setValidInvalidInput = (element, isValid) => {

    if (isValid) {
        element.classList.add('is-valid');
        element.classList.remove('is-invalid');
    } else {
        element.classList.remove('is-valid');
        element.classList.add('is-invalid');
    }
}

export const checkRadio = (element) => {
    var inputs = element.getElementsByTagName('input');
    var checked = false;

    for (let index = 0; index < inputs.length; index++)
    {
        if (inputs.checked)
        {
            checked = true;
            break;
        }
    }

    setValidInvalidInput(inputs, checked);
}

export const checkMinMaxLenght = (element, min, max, isNumber) =>
{
    var value = element.value;

    if(isNumber === true)
    {
        value = onlyNumbers(element.value);
    }


    if (value.length >= min && value.length <= max)
    {
        setValidInvalidInput(element, true);
        return true;
    } else {
        setValidInvalidInput(element, false);
        return false;
    }
}


export const checkNumInstalacao = (element) =>
{
    if(checkMinMaxLenght(element, 10, 10, true))
    {
        if(element.value[0] != 3)
        {
            setValidInvalidInput(element, false);
            return 3;
        }
    }else{
        return 10;
    }
}



export const checkCupom = async (element) => {

    if (element.value.length > 0) {
        var cupons = await GetWP('cupons/?cupom=' + element.value);

        if (cupons && cupons['data'] && cupons['data'].length > 0) {
            var dataAtual = new Date();
            dataAtual = new Date(dataAtual.setHours(dataAtual.getHours() - 3));
            var cupom = null;

            for (var index = 0; index < cupons['data'].length; index++) {

                if(!cupons['data'][index]['Data de validade'])
                {
                    cupom = cupons['data'][index];
                    break;
                }else{
                    var dataValidade = new Date(cupons['data'][index]['Data de validade']);

                    if (dataValidade > dataAtual) {
                        cupom = cupons['data'][index];
                        break;
                    }
                }
                
            }

            if (cupom != null) {
                setValidInvalidInput(element, true);
                return cupom;
            } else {
                setValidInvalidInput(element, false);
            }
        } else {
            setValidInvalidInput(element, false);
        }
    } else {
        element.classList.remove('is-valid');
        element.classList.remove('is-invalid');
    }

    var cumpom = {
        "Código": "SOLATIO",
        "Economia (%)": 15,
        "Data de validade": "-",
        "Não é CMU": true
    };
    
    return cumpom;
}

export const checkData = (element) => {

    checkEmpty(element);
    var onlyNumbersDate = onlyNumbers(element.value);

    if (element && element.value && onlyNumbersDate.length === 8) {
        var data = element.value.split('/');
        var day = parseInt(data[0]);
        var month = parseInt(data[1]);
        var year = parseInt(data[2]);

        if (day > 0 && day <= 31 && month > 0 && month <= 12 && year > 1000) {

            var dataAtual = new Date();
            var dataAntiga = new Date(new Date().setFullYear(new Date().getFullYear() - 120));
            dataAtual = new Date(new Date().setFullYear(new Date().getFullYear() - 18));
            var dataNova = new Date(year, month, day);
        

            if (!dataNova || (dataAtual > dataNova) === false || (dataNova > dataAntiga) === false) {
                setValidInvalidInput(element, false);
            } else {
                setValidInvalidInput(element, true);
            }
        } else {
            setValidInvalidInput(element, false);
        }
    }else{
        setValidInvalidInput(element, false);
        
    }
    
}

export const checkNome = (element) => {

    if (element) {
        if (element.value && element.value.trim().split(" ").length >= 2)
        {
            setValidInvalidInput(element, true);
        } else {
            setValidInvalidInput(element, false);
        }
    }
}

export const checkEmail = (element) => {

    if (element) {

        var value = element.value;

        if (value && value !== '') {

            // eslint-disable-next-line
            var regex = /^(([a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+(\.[a-zA-Z0-9!#$%&'*+\/=?^_`{|}~-]+)*))@(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})$/;

            if (value && regex.test(value)) {
                setValidInvalidInput(element, true);              
            } else {
                setValidInvalidInput(element, false);
            }
        }else{
            element.classList.remove('is-valid');
            element.classList.remove('is-invalid');
        }
    }
}

export const checkTelefone = (element) => {

    if (element) {
        // eslint-disable-next-line
        var regex = /(\(?\d{2}\)?\s)?(\d{5}\-\d{4})/;

        var value = element.value;

        if (value && onlyNumbers(value).length > 9 && regex.test(value)){
                setValidInvalidInput(element, true);
        } else {
            setValidInvalidInput(element, false);
        }
    }
}

export const checkEmpty = (element) => {

    if (element) {
        var value = element.value;

        if (value && value !== '') {
            setValidInvalidInput(element, true);
        } else {
            setValidInvalidInput(element, false);
        }
    }
}

export const validarCPF = (element) => {
    var isValid = true;
    if (element.value) {
        var cpf = element.value.replace(/[^\d]+/g, '');
        if (cpf == '') isValid = false;
        // Elimina CPFs invalidos conhecidos	
        if (cpf.length != 11 ||
            cpf == "00000000000" ||
            cpf == "11111111111" ||
            cpf == "22222222222" ||
            cpf == "33333333333" ||
            cpf == "44444444444" ||
            cpf == "55555555555" ||
            cpf == "66666666666" ||
            cpf == "77777777777" ||
            cpf == "88888888888" ||
            cpf == "99999999999")
            isValid = false;
        // Valida 1o digito	
        var add = 0;
        for (var i = 0; i < 9; i++)
            add += parseInt(cpf.charAt(i)) * (10 - i);
        var rev = 11 - (add % 11);
        if (rev == 10 || rev == 11)
            rev = 0;
        if (rev != parseInt(cpf.charAt(9)))
            isValid = false;
        // Valida 2o digito	
        add = 0;
        for (i = 0; i < 10; i++)
            add += parseInt(cpf.charAt(i)) * (11 - i);
        rev = 11 - (add % 11);
        if (rev == 10 || rev == 11)
            rev = 0;
        if (rev != parseInt(cpf.charAt(10)))
            isValid = false;
    } else {
        isValid = false;
    }

    if (isValid === true)
    {
        setValidInvalidInput(element, true);
    } else {
        setValidInvalidInput(element, false);
    }

    return isValid;
}

export const validarCNPJ = (element) => {
    
    var value = element.value;
    var cnpj = onlyNumbers(value);
    var isValid = true;

    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') isValid = false;
    
    if (cnpj.length != 14)
        isValid = false;

    // Elimina CNPJs invalidos conhecidos
    if (cnpj == "00000000000000" ||
        cnpj == "11111111111111" ||
        cnpj == "22222222222222" ||
        cnpj == "33333333333333" ||
        cnpj == "44444444444444" ||
        cnpj == "55555555555555" ||
        cnpj == "66666666666666" ||
        cnpj == "77777777777777" ||
        cnpj == "88888888888888" ||
        cnpj == "99999999999999")
        isValid = false;
        
    var tamanho = cnpj.length - 2;
    var numeros = cnpj.substring(0, tamanho);
    var digitos = cnpj.substring(tamanho);
    var soma = 0;
    var pos = tamanho - 7;

    for (var i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;

        if (pos < 2) {
            pos = 9;
        }
    }
    
    var resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
    
    if (resultado != digitos.charAt(0))
        isValid = false;
        
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;

        if (pos < 2)
            pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

    if (resultado != digitos.charAt(1)) {
        isValid = false;
    }

    setValidInvalidInput(element, isValid);

    return isValid;
}


export const checkGNV = async (element) => {

    var retorno = null;
    var isValid = validarCPF(element);

    if(isValid == true)
    {
        var data = await GetWP('checkGNV?gnv=' + onlyNumbers(element.value));

        if (data)
        {
            retorno = data;
            
/*             if (data.status === 'ok') {
                isValid = true;
            } */
        }
    }

    setValidInvalidInput(element, isValid);

    return retorno;
}
    
export const checkCep = async(element) => {
    var retorno = null;

    if (element) {
        var cep = onlyNumbers(element.value);
        
        if (cep.length >= 8) {


            var data = await GetGenerico('https://viacep.com.br/ws/' + cep + '/json/');

            if (data)
            {
                if (data.erro === true || data.erro === 'true')
                {
                    setValidInvalidInput(element, false);
                }else if (data.status === 'ok') {
                    var endereco = {};
                    endereco["cep"] = data.cep;
                    endereco["logradouro"] = data.logradouro;
                    endereco["bairro"] = data.bairro;
                    endereco["cidade"] = data.localidade;
                    endereco["uf"] = data.uf;
                    endereco["numero"] = '';
                    endereco["complemento"] = '';
                    endereco['canHire'] = false;

                    if(cep[0] == 3)
                    {
                        endereco['canHire'] = true;
                    }

                    retorno = endereco;
                    setValidInvalidInput(element, true);
                } else
                {
                    setValidInvalidInput(element, false);
                }
                    
                
            }
        } else {
            setValidInvalidInput(element, false);
        }
    }

    return retorno;
}


export const isHomolog = () => {
    var homolog = window.location.href.includes('localhost');
    if (!homolog) {
        homolog = window.location.href.includes('homolog.');
    }

    return homolog;
}  